<template>
  <div>
    <FilterOverview title="Airlines" :showFilterInitially="false">
      <template #table="{ tableHeight }">
        <b-overlay :show="isLoading">
          <div
            class="p-6 card card-body"
            style="min-width: 900px; overflow: auto"
            :style="{ height: tableHeight + 80 + 'px' }"
          >
            <div class="bg-primary plus-sign" v-b-modal.modalAirlineErstellen>+</div>
            <div
              v-for="airline in airline"
              :key="airline.airlineKuerzel"
              class="mt-4 d-flex align-items-center"
              style="gap: 12px"
            >
              <b-form-group label="Airline">
                <h6 class="mb-0" style="width: 150px">{{ airline.airlineKuerzel }}</h6>
              </b-form-group>
              <b-form-group label="Optionsfrist">
                <b-form-input
                  v-model="airline.optionsfrist"
                  @update="changeOptionsfrist(airline.optionsfrist, airline.airlineKuerzel)"
                  debounce="500"
                  type="number"
                  style="width: 150px"
                />
              </b-form-group>
              <b-form-group label="Ticketingfrist">
                <b-form-input
                  v-model="airline.ticketingfrist"
                  @update="changeTicketingfrist(airline.ticketingfrist, airline.airlineKuerzel)"
                  debounce="500"
                  type="number"
                  style="width: 150px"
                />
              </b-form-group>
              <div class="ml-2" @click="deleteAirline(airline.airlineKuerzel)" style="cursor: pointer">
                <i class="fas fa-trash" style="color: black"></i>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>
    </FilterOverview>
    <AirlineErstellenModal @airlineErstellt="onAirlineErstellt" />
  </div>
</template>

<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import AirlineErstellenModal from '@/components/modals/airline-erstellen.vue';
import apiService from '@/core/common/services/api.service';

export default {
  components: {
    FilterOverview,
    AirlineErstellenModal,
  },
  data() {
    return {
      airline: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadAirlines();
  },
  methods: {
    loadAirlines() {
      this.isLoading = true;
      apiService
        .get('/airline')
        .then(result => {
          this.airline = result.data.result;
        })
        .finally(() => (this.isLoading = false));
    },
    onAirlineErstellt() {
      this.loadAirlines();
    },
    deleteAirline(airlineKuerzel) {
      this.isLoading = true;
      apiService
        .delete('/airline/' + airlineKuerzel)
        .then(() => {
          this.loadAirlines();
          this.toast(`Airline: ${airlineKuerzel} erfolgreich gelöscht.`);
        })
        .finally(() => (this.isLoading = false));
    },
    changeOptionsfrist(optionsfrist, airlineKuerzel) {
      this.isLoading = true;
      apiService
        .put('/airline/' + airlineKuerzel, { optionsfrist })
        .then(() => {
          this.loadAirlines();
          this.toast(`Airline: ${airlineKuerzel} erfolgreich geändert.`);
        })
        .finally(() => (this.isLoading = false));
    },
    changeTicketingfrist(ticketingfrist, airlineKuerzel) {
      this.isLoading = true;
      apiService
        .put('/airline/' + airlineKuerzel, { ticketingfrist })
        .then(() => {
          this.loadAirlines();
          this.toast(`Airline: ${airlineKuerzel} erfolgreich geändert.`);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
